import $ from 'jquery';

export default function lightbox() {
  
  // function init(Fresco) {
  //   $('.box-photo-lightbox .box-photo-lightbox__link').on('click', function(event) {
  //     event.preventDefault();
  //     const selectedImgId = $(this).attr('id');
  //     const groupImg = [];
  //     let position;
      
  //     $('.box-photo-lightbox .box-photo-lightbox__link').each(function(index) {
  //       const imgId = $(this).attr('id');
  //       const imgLink = $(this).attr('href');
  //       const imgTitle = $(this).attr('title');

  //       if (imgId === selectedImgId){
  //         position = index+1;
  //       }

  //       groupImg.push({url: imgLink, caption: imgTitle});
  //     });
        
  //     Fresco.show(groupImg, {
  //       loop: true,
  //       thumbnails: true,
  //       keyboard: {
  //         left: true,
  //         right: true,
  //         esc: true
  //       }
  //     }, position);
  //   });
  // }
  
  $( document ).ready(function() {
    if($('.box-photo-lightbox').length > 0) {
   
      return import(/* webpackChunkName: "fresco" */ '@staaky/fresco').then(({ default: Fresco }) => {
        
        init(Fresco);
   
      }).catch(error => 'An error occurred while loading the component');
     
    }
  });
}